import React, { useState, useEffect } from 'react';
import R from 'ramda';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import { useDispatch } from 'react-redux';

import { attemptGetUser } from '_store/thunks/user';

import WelcomePage from '_components/pages/WelcomePage';
import LoginPage from '_components/pages/LoginPage';
import RegisterPage from '_components/pages/RegisterPage';
import HomePage from '_components/pages/HomePage';
import TodoPage from '_components/pages/TodoPage';
import SettingsPage from '_components/pages/SettingsPage';
import LostPage from '_components/pages/LostPage';

import ProductPage from '_components/pages/ProductPage';
import OrderPage from '_components/pages/OrderPage';


import Navigation from '_components/organisms/Navigation';
import Footer from '_components/organisms/Footer';

import styles from './styles.module.css';
import Products from '../../pages/Products/Products';
import Orders from '../../pages/Orders/Orders';
import SmartTags from '../../pages/SmartTags/SmartTags';
import Returns from '../../pages/Returns/Returns';
import Inventory from '../../pages/Inventory/Inventory';
import Dashboard from '../../pages/Dashboard/Dashboard';
import ProductReview from '../../pages/ProductReview/ProductReview';


export default function Main() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    let subscribed = true;

    dispatch(attemptGetUser())
      .then(() => subscribed && setLoading(false))
      .catch(R.identity);

    return () => {
      subscribed = false;
    };
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    !loading && (
      <React.Fragment>
        <ReactNotifications />
        <Navigation />
        <div className={styles.root}>
          <Routes>
            <Route path="/" element={<WelcomePage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="register" element={<RegisterPage />} />
            <Route path="Dashboard" element={<Orders />} />
            <Route path="home" element={<Orders />} />

            <Route path="Products" element={<Products />} />
            <Route path="Orders" element={<Orders />} />
            
            <Route path="Scan" element={<SmartTags />} />
            <Route path="Return" element={<Returns />} />

            <Route path="Inventory" element={<Inventory />} />
            <Route path="Review" element={<ProductReview />} />

            <Route path="todo" element={<TodoPage />} />

            {/* <Route path="addproduct" element={<ProductPage />} />
            <Route path="addorder" element={<OrderPage />} /> */}

            <Route path="settings/*" element={<SettingsPage />} />
            <Route path="*" element={<LostPage />} />
          </Routes>
        </div>
        <Footer />
      </React.Fragment>
    )
  );
}
