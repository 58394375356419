import request from 'superagent';
import { handleSuccess, handleError } from '_utils/api';

export const postProduct = info =>
  request.post('/api/products')
    .send(info)
    .then(handleSuccess)
    .catch(handleError);

export const getProducts = () =>
  request.get('/api/products')
    .then(handleSuccess)
    .catch(handleError);

export const putToggleCompleteProduct = info =>
  request.put('/api/products/complete')
    .send(info)
    .then(handleSuccess)
    .catch(handleError);

export const putProduct = info =>
  request.put('/api/products')
    .send(info)
    .then(handleSuccess)
    .catch(handleError);

export const deleteProduct = info =>
  request.delete('/api/products')
    .send(info)
    .then(handleSuccess)
    .catch(handleError);
