import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Space,
  Modal,
  Select,
  ColorPicker,
  Upload,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined, PoundCircleOutlined, QuestionCircleFilled } from '@ant-design/icons';
import { attemptAddProduct } from '_store/thunks/products';
import useKeyPress from '_hooks/useKeyPress';
import TextArea from 'antd/es/input/TextArea';
import './AddProduct.styles.scss';

export default function AddProduct({ isModalOpenFun, isModalOpen, products }) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [bodyHtml, setBodyHtml] = useState('');
  const [vendor, setVendor] = useState('');
  const [productType, setProductType] = useState('');
  const [handle, setHandle] = useState('');
  const [templateSuffix, setTemplateSuffix] = useState('');
  const [tags, setTags] = useState('0.00');
  const [confirmLoading, setConfirmLoading] = useState(false);

  const [imagePreview, setImagePreview] = useState(null);
  // const { Option } = Select;

  const handleAddProduct = () => {
    const product = {
      title,
      body_html: bodyHtml,
      vendor,
      product_type: productType,
      handle,
      template_suffix: templateSuffix,
      tags,
    };
    dispatch(attemptAddProduct(product));
    setTitle('');
    setBodyHtml('');
    setVendor('');
    setProductType('');
    setHandle('');
    setTemplateSuffix('');
    setTags('');
  };

  useKeyPress('Enter', handleAddProduct);

//-------------------------------------------------

  // UPLOAD ITEM
  const beforeUpload = (file) => {
    setImagePreview(file.url || file.thumbUrl);
    return true;
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

//-------------------------------------------------


  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: {
        span: 26,
        offset: 0,
      },
      sm: {
        span: 26,
        offset: 2,
      },
    },
  };

//-------------------------------------------------
  // MODAL FUNCTIONS
  const handleCancel = () => {
    isModalOpenFun(false);
  };

  const AddRow = (values) => {
    console.log('here goes the values', values);
    setConfirmLoading(true);
    setTimeout(() => {
      isModalOpenFun(false);
      setConfirmLoading(false);
      const randomNumber = parseInt(Math.random() * 1000);
      const newProduct = {
        id: randomNumber,
        logo: <img src={values.Upload[0].thumbUrl} style={{ width: '50px', height: '50px' }} />,
        product: values.title,
        status: 'Draft',
        inventory: '150 in stock for 3 variants',
        type: values.product_type,
        vendor: 'Kareem and Marc',

        // IMPORTANT: THIS PART IS FOR SENDING SIZES !!
        // size: values.Sizes.map((item) => item.fieldName),
      };
      products((pre) => [...pre, newProduct]);
    }, 2000);
  };
//-------------------------------------------------

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCancel}
      bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
      footer={[
        <Button onClick={handleCancel}>Cancel</Button>,
        <Button
          loading={confirmLoading}
          form="myForm"
          key="submit"
          htmlType="submit"
          type="primary"
        >
          Ok
        </Button>,
      ]}
      confirmLoading={confirmLoading}
    >
      <div>
        <Form
          id="myForm"
          style={{
            maxWidth: 800,
            marginTop: '2em',
          }}
          onFinish={(values) => {
            AddRow(values);
          }}
          onFinishFailed={(error) => {
            console.log({ error });
          }}
          initialValues={{ Price: '00.00', Size: 'Small' }}
        >
          <Form.Item
            key={1}
            name="title"
            label="Product Title"
            rules={[
              {
                required: true,
                message: 'Please input product name',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            key={2}
            name="Product Description"
            label="Product Description"
          >
            <TextArea />
          </Form.Item>

          <Form.Item
            key={3}
            label="Product Type"
            style={{
              display: 'inline-block',
              width: 'calc(60% - 8px)',
            }}
            name="product_type"
            rules={[
              {
                required: true,
                message: 'Please input product type',
              },
            ]}
          >
            <Input
              // name="product_type"
              // value={productType}
              // onChange={(e) => setProductType(e.target.value)}
              placeholder="Product Type"
            />
          </Form.Item>
          <Form.Item
            key={4}
            style={{
              display: 'inline-block',
              width: 'calc(40% - 8px)',
              margin: '0 8px',
            }}
            name="handle"
            label="Handle"
          >
            <Input
              // name="handle"
              // value={handle}
              // onChange={(e) => setHandle(e.target.value)}
              placeholder="Handle"
            />
          </Form.Item>

          <Form.Item
            key={5}
            label="Template Suffix"
            style={{
              display: 'inline-block',
              width: 'calc(60% - 8px)',
            }}
            name="template_suffix"
          >
            <Input />
          </Form.Item>
          <Form.Item
            key={6}
            name="tags"
            label="Tags"
            style={{
              display: 'inline-block',
              width: 'calc(40% - 8px)',
              margin: '0 8px',
            }}
          >
            <Input />
          </Form.Item>

          <Form.Item
            key={7}
            label="Pricing"
            style={{
              display: 'inline-block',
              width: 'calc(30% - 8px)',
              // margin: "0 8px",
            }}
            name="Price"
          >
            <Input
              // name="Price"
              prefix={<PoundCircleOutlined style={{ color: '#616972' }} />}
              // value={tags}
              // defaultValue="0.00"
              // onChange={(e) => setTags(e.target.value)}
              placeholder="Price"
            />
          </Form.Item>
          <Form.Item
            key={8}
            style={{
              display: 'inline-block',
              width: 'calc(60% - 16px)',
              marginLeft: '2em',
            }}
            className="CheckBox"
          >
            <Checkbox>charge tax on this product</Checkbox>
          </Form.Item>

          <Form.Item
            key={9}
            name="Upload"
            label="Upload"
            valuePropName="fileList"
            getValueFromEvent={normFile}

          >
            <Upload
              action="/upload.do"
              listType="picture-card"
              beforeUpload={beforeUpload}
              accept="image/*"
              // onPreview={handlePreview}
              maxCount={3}
            >
                {imagePreview ? (
        <img src={imagePreview} alt="Preview" style={{ width: '100%' }} />
      ) : (
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      )}
            </Upload>
          </Form.Item>
          <Form.List key={10} name="Sizes">
            {(fields, { add, remove }, { errors }) => (
              <React.Fragment>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index !== 0 && formItemLayoutWithOutLabel)}
                    label={index === 0 ? 'Size' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            'Please input desired size or delete this field.',
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        placeholder="Size"
                        style={{
                          width: '30%',
                        }}
                      />
                    </Form.Item>
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        style={{ marginLeft: '0.5em' }}
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{
                      width: '60%',
                    }}
                    icon={<PlusOutlined />}
                  >
                    Add Size
                  </Button>
      
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </React.Fragment>
            )}
          </Form.List>
          <Form.List key={11} name="Colors">
            {(fields, { add, remove }, { errors }) => (
              <React.Fragment>
                {fields.map((field, index) => (
                  <Form.Item
                    {...(index !== 0 && formItemLayoutWithOutLabel)}
                    label={index === 0 ? 'Color' : ''}
                    required={false}
                    key={field.key}
                    style={index !== 0 ? { marginLeft: '0.5em' } : {}}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message:
                            'Please input desired Color or delete this field.',
                        },
                      ]}
                      noStyle
                    >
                      <Input
                        placeholder="Color"
                        style={{
                          width: '30%',
                        }}
                      />
                    </Form.Item>
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        style={{ marginLeft: '0.5em' }}
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{
                      width: '60%',
                    }}
                    icon={<PlusOutlined />}
                  >
                    Add Color
                  </Button>
                  {/* <Button
                    type="dashed"
                    onClick={() => {
                      add("The head item", 0);
                    }}
                    style={{
                      width: "60%",
                      marginTop: "20px",
                    }}
                    icon={<PlusOutlined />}
                  >
                    Add field at head
                  </Button> */}
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </React.Fragment>
            )}
          </Form.List>

        </Form>
        {' '}
      </div>
    </Modal>
  );
}
