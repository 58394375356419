import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import R from 'ramda';

import Navbar from 'react-bulma-companion/lib/Navbar';
import Container from 'react-bulma-companion/lib/Container';
import Image from 'react-bulma-companion/lib/Image';
import Title from 'react-bulma-companion/lib/Title';
import Button from 'react-bulma-companion/lib/Button';
import './Navigation.styles.scss';
import UserDropdown from '_components/molecules/UserDropdown';
import Lokal from '../../../assets/images/LOKAL.png';

export default function Navigation() {
  const { pathname } = useLocation();
  const { user } = useSelector(R.pick(['user']));

  const [auth, setAuth] = useState(!R.isEmpty(user));
  const [open, setOpen] = useState(false);
  const [isActive, setisActive] = useState(false);

  // To get screen width

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update the screen width when the window is resized
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setAuth(!R.isEmpty(user));
  }, [user]);

  const toggleDropdown = () => setOpen(!open);

  const closeDropdown = () => setOpen(false);

  const toggleBurger = () => {
    if (screenWidth < 1000) {
      setisActive(!isActive);
    }
  };
  // const isHome =
  //   pathname.length === 5
  //     ? pathname === "/home"
  //     : R.slice(0, 6, pathname) === "/home/";
  const isDashboard =    pathname.length === 10
    ? pathname === '/Dashboard'
    : R.slice(0, 6, pathname) === '/Dashboard/';

  // const isProduct =
  //   pathname.length === 8
  //     ? pathname === "/addproduct"
  //     : R.slice(0, 6, pathname) === "/addproduct/";
  const isProducts =    pathname.length === 9
    ? pathname === '/products'
    : R.slice(0, 6, pathname) === '/products/';
  const isOrders = pathname.length === 7
    ? pathname === '/orders'
    : R.slice(0, 6, pathname) === '/orders/';

    const isInventory = pathname.length === 10
    ? pathname === '/inventory'
    : R.slice(0, 6, pathname) === '/inventory/';


  // const isOrder =
  //   pathname.length === 9
  //     ? pathname === "/addorder"
  //     : R.slice(0, 6, pathname) === "/addorder/";

  const isSettings =    pathname.length === 9
    ? pathname === '/settings'
    : R.slice(0, 10, pathname) === '/settings/';

  return (
    <Navbar fixed="top" shadow className="NavbarContainer">
      <Container>
        <Navbar.Brand>
          <Navbar.Item
            to={auth ? '/orders' : '/login'}
            aria-label="main navigation"
            component={Link}
          >
  
            <Image>
              <Image.Content src={Lokal} />
            </Image>
          </Navbar.Item>
          <div className="navbar-brand-right">
            {!auth && (
              <Navbar.Item
                className="is-hidden-desktop"
                to="/login"
                component={Link}
              >
                <Button color="success">Login</Button>
              </Navbar.Item>
            )}
            {auth && (
              <React.Fragment>
                <a
                  data-target="navbarData"
                  onClick={toggleBurger}
                  role="button"
                  className={`navbar-burger CustomBurger ${
                    isActive ? 'is-active' : ''
                  }`}
                  aria-label="menu"
                  aria-expanded="false"
                >
                  <span style={{ height: '2px', backgroundColor: '#b1ec05' }} />
                  <span style={{ height: '2px', backgroundColor: '#b1ec05' }} />
                  <span style={{ height: '2px', backgroundColor: '#b1ec05' }} />
                </a>
                <Navbar.Item
                  className="is-hidden-desktop"
                  onClick={toggleDropdown}
                  onKeyPress={toggleDropdown}
                  hoverable
                  component="a"
                >
                  <Image size="32x32">
                    <Image.Content
                      className="profile-img"
                      src={user.profilePic || '/images/default-profile.png'}
                    />
                  </Image>
                  <span className="dropdown-caret" />
                </Navbar.Item>
              </React.Fragment>
            )}
          </div>
        </Navbar.Brand>

        {auth ? (
          <>
            <Navbar.Menu
            id="navbarData"
            className={`navbar-menu ${isActive ? 'is-active' : ''}`}
          >
            <Navbar.Start 
            >
              
              {/* <Navbar.Item
                // className="is-hidden-mobile"
                to="/home"
                active={isHome}
                tab
                component={Link}
              >
                <Title size="6">Home</Title>
              </Navbar.Item> */}
              {/* <Navbar.Item
                // className="is-hidden-desktop"
                to="/Dashboard"
                active={isDashboard}
                tab
                component={Link}
                onClick={toggleBurger}
              >
                <h5
                  style={{
                    fontWeight: 'bold',
                    color: isActive ? 'black' : 'white',
                  }}
                >
                  Dashboard
                </h5>
              </Navbar.Item> */}

              {/* <Navbar.Item
                // className="is-hidden-desktop"
                to="/products"
                active={isProducts}
                tab
                component={Link}
                onClick={toggleBurger}
              >
                <h5
                  style={{
                    fontWeight: 'bold',
                    color: isActive ? 'black' : 'white',
                  }}
                >
                  Products
                </h5>
                {' '}
              </Navbar.Item> */}

              <Navbar.Item
                // className="is-hidden-desktop"
                to="/orders"
                active={isOrders}
                tab
                component={Link}
                onClick={toggleBurger}
              >
                <h5
                  style={{
                    fontWeight: 'bold',
                    color: isActive ? 'black' : 'white',
                  }}
                >
                  Orders
                </h5>
                {' '}
              </Navbar.Item>
              <Navbar.Item
                // className="is-hidden-desktop"
                to="/inventory"
                active={isInventory}
                tab
                component={Link}
                onClick={toggleBurger}
              >
                <h5
                  style={{
                    fontWeight: 'bold',
                    color: isActive ? 'black' : 'white',
                  }}
                >
                  Inventory
                </h5>
                {' '}
              </Navbar.Item>
              <Navbar.Item
                // className="is-hidden-desktop"
                to="/settings"
                active={isSettings}
                tab
                component={Link}
                onClick={toggleBurger}
              >
                <h5
                  style={{
                    fontWeight: 'bold',
                    color: isActive ? 'black' : 'white',
                  }}
                >
                  Settings
                </h5>
              </Navbar.Item>
            </Navbar.Start>
          </Navbar.Menu>
          <Navbar.Menu>
            <Navbar.End>
            <Navbar.Item
             className="is-hidden-mobile"
             onClick={toggleDropdown}
             onKeyPress={toggleDropdown}
             hoverable
             component="a"
           >
             <Image size="32x32">
               <Image.Content
                 className="profile-img"
                 src={user.profilePic || '/images/default-profile.png'}
               />
             </Image>
             <span className="dropdown-caret" />
           </Navbar.Item>
            </Navbar.End>
             

          </Navbar.Menu>
          </>
        
        ) : (
          <Navbar.Menu>
            <Navbar.End>
              <Navbar.Item to="/login" component={Link}>
                <Button color="success">Login</Button>
              </Navbar.Item>
            </Navbar.End>
          </Navbar.Menu>
        )}
        <UserDropdown
          open={open}
          closeDropdown={closeDropdown}
          // style={{ position: 'relative' }}
        />
      </Container>
    </Navbar>
  );
}
