import request from 'superagent';
import { handleSuccess, handleError } from '_utils/api';


export const getInventory = () =>
  request.get('/api/products/reviews')
    .then(handleSuccess)
    .catch(handleError);


export const postOrder = (info) =>
  request.post('/api/orders').send(info).then(handleSuccess).catch(handleError);


  
export const getOrders = async () => {
  const data = await request
    .get('/api/products/reviews')
    .then(handleSuccess)
    .catch(handleError);

    const inventoryItems = data.map((inventory) => {
      const { title, id, created_at, vendor } = inventory;

      // Convert updated_at to a Date object and format it as a string
      const formattedUpdatedAt = new Date(created_at).toDateString();
    
      // Use formattedUpdatedAt wherever you need it in your application
    
      return {
        title,
        id,
        vendor,
        created_at: formattedUpdatedAt, // Replace the original updated_at with the formatted version
      };
    });


  return inventoryItems;
};

