import "./Returns.style.scss";
import React, { useState, useEffect } from 'react';


const Returns = () => {
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [orderNumber, setOrderNumber] = useState('');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [products, setProducts] = useState([]);

    const [paymentMethod, setPaymentMethod] = useState('');
    const [paymentField, setPaymentField] = useState('');
    const [returnReason, setReturnReason] = useState('');
    
    const handleSubmit = (e) => {
        e.preventDefault();


        debugger;
        if (!email && !phoneNumber) {
            alert('Please provide your email or phone number');
            return;
        }

        if (!selectedProducts.length) {
            alert('Please select at least one product to return');
            return;
        }

        const requestData = {
            email: email,
            phoneNumber: phoneNumber,
            orderNumber: orderNumber,
            selectedProducts: selectedProducts,
            paymentMethod: paymentMethod,
            paymentField: paymentField,
            returnReason: returnReason
        };

        fetch(`/api/orders/request-return?email=${email}&phone=${phoneNumber}&order=${orderNumber}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        })
        .then(data => {

            if (data.status === 200) {
                // Handle the response from the backend
                alert('Request submitted successfully');
                window.location.href = 'https://lokaleg.com';
            } else {
                alert('Request failed, kindly send your request to operations@lokaleg.com');
            }

        })
        .catch(error => console.error(error));
    };

    useEffect(() => {

        // Fetch the list of products here
        // fetch(`/api/orders/request-return?email=${email}&phone=${phoneNumber}&order=${orderNumber}`)
        //     .then(response => response.json())
        //     .then(data => setProducts(data))
        //     .catch(error => console.error(error));
    }, []);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handleOrderNumberChange = (e) => {
        const value = e.target.value;
        setOrderNumber(value);

        if (value.length > 3) {
            
            inquireOrder(value);
        }
    };

    const handleAlternativePaymentChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const handleAlternativePaymentFieldChange = (e) => {
        setPaymentField(e.target.value);
    };

    const handleReturnReason = (e) => {
        setReturnReason(e.target.value);
    };


    const inquireOrder = async (ordernumber) => {
        // Fetch the list of products here
        fetch(`/api/orders/request-return?email=${email}&phone=${phoneNumber}&order=${ordernumber}`)
            .then(response => response.json())
            .then(data => setProducts(data.items))
            .catch(error => console.error(error));
    };

    const handleProductSelection = (productId) => {
        if (selectedProducts.includes(productId)) {
            setSelectedProducts(selectedProducts.filter((id) => id !== productId));
        } else {
            setSelectedProducts([...selectedProducts, productId]);
        }

        console.log(selectedProducts);
    };

    return (<div className="popup">
                <div className="popup-inner">
                <h1 style={{ textAlign: 'center', fontWeight: 'bold' }}>Returning an item? No problem, just tell me more about your order so I can get your details :)</h1>
                    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <div>
                            <label>
                                Email:
                                <input type="email" value={email} onChange={handleEmailChange} style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ddd' }}/>
                            </label>
                        </div>
                        <div>
                            <label>
                                Phone Number:
                                <input type="tel" value={phoneNumber} onChange={handlePhoneNumberChange} style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ddd' }}/>
                            </label>
                        </div>
                        <div>
                            <label>
                                Order Number:
                                <input type="text" value={orderNumber} onChange={handleOrderNumberChange} style={{ padding: '10px', borderRadius: '4px', border: '1px solid #ddd' }} />
                            </label>
                        </div>
                        {products && (products.length > 0) && <div>
                            <label>
                                Alternative Payment:
                            </label>
                            <select id="alternative-payment" name="alternative-payment" style={{ width:'100%', padding: '10px', borderRadius: '4px', border: '1px solid #ddd'  }} onChange={handleAlternativePaymentChange}>
                                <option value="">Select...</option>
                                <option value="instapay">Instapay</option>
                                <option value="iban">IBAN</option>
                                <option value="card">Card</option>   
                                <option value="telda">Telda</option>        
                            </select>
                        </div>}
                        {products && (products.length > 0) && <div>
                            <label>
                                IPN Name/IBAN/Mobile Number:
                            </label>
                            <input type="text" value={paymentField} onChange={handleAlternativePaymentFieldChange} id="payment-field" name="payment-field" style={{ width:'100%', padding: '10px', borderRadius: '4px', border: '1px solid #ddd'  }} />
                        </div>}

                        {products && (products.length > 0) && <div>
                            <label>
                                Return Reason:
                            </label>
                            <input type="text" value={returnReason} onChange={handleReturnReason} id="return-reason" name="return-reason" style={{ width:'100%', padding: '10px', borderRadius: '4px', border: '1px solid #ddd'  }} />
                        </div>}

                        {products && (products.length > 0) && <div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{ paddingRight: '10px', color: 'white' }}>Return</th>
                                            <th style={{ paddingRight: '10px' }}>Product Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products.map((product, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedProducts.includes(product.id)}
                                                        onChange={() => handleProductSelection(product.id)}
                                                    />
                                                </td>
                                                <td>{product.name}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <button type="submit" style={{ padding: '10px', borderRadius: '4px', border: 'none', backgroundColor: '#007BFF', color: 'white', cursor: 'pointer' }}>Submit</button>        
                        </div>}
                    </form>
                </div>
            </div>
    );
};

export default Returns;
