import update from 'immutability-helper';
import R from 'ramda';

import {
  SET_PRODUCTS, ADD_PRODUCT, TOGGLE_COMPLETE_PRODUCT, UPDATE_PRODUCT, REMOVE_PRODUCT,
} from '_store/actions/products';

import { LOGOUT_USER } from '_store/actions/user';

export function product(state = {
  completed: false,
}, action) {
  switch (action.type) {
    case ADD_PRODUCT:
      return update(state, {
        id: { $set: action.id },
        title: { $set: action.title },
        bodyHtml: { $set: action.bodyHtml },
        productType: { $set: action.productType },
        createdAt: { $set: action.createdAt },
        handle: { $set: action.handle },
        updatedAt: { $set: action.updatedAt },
        publishedAt: { $set: action.publishedAt },
        templateSuffix: { $set: action.templateSuffix },
        tags: { $set: action.tags },
      });
    case TOGGLE_COMPLETE_PRODUCT:
      return update(state, {
        completed: { $apply: x => !x },
      });
    case UPDATE_PRODUCT:
      return update(state, {
        text: { $set: action.text },
        updatedAt: { $set: action.updatedAt },
      });
    default:
      return state;
  }
}

export default function products(state = [], action) {
  const index = R.findIndex(R.propEq('id', action.id), state);
  const updatedAtIndex = { $splice: [[index, 1, product(state[index], action)]] };

  switch (action.type) {
    case SET_PRODUCTS:
      return update(state, { $set: action.products });
    case ADD_PRODUCT:
      return update(state, { $push: [product(undefined, action)] });
    case TOGGLE_COMPLETE_PRODUCT:
      return update(state, updatedAtIndex);
    case UPDATE_PRODUCT:
      return update(state, updatedAtIndex);
    case REMOVE_PRODUCT:
      return update(state, { $splice: [[index, 1]] });
    case LOGOUT_USER:
      return [];
    default:
      return state;
  }
}
