import request from 'superagent';
import { handleSuccess, handleError } from '_utils/api';


export const getInventory = () =>
  request.get('/api/v0.1/dashboard/inventory')
    .then(handleSuccess)
    .catch(handleError);


export const postOrder = (info) =>
  request.post('/api/orders').send(info).then(handleSuccess).catch(handleError);


  
export const getOrders = async () => {
  const data = await request
    .get('/api/v0.1/dashboard/inventory')
    .then(handleSuccess)
    .catch(handleError);

    const inventoryItems = data.map((inventory) => {
      const { title, price, sku, inventory_quantity, option1, option2, option3 , updated_at } = inventory;

      // Convert updated_at to a Date object and format it as a string
      const formattedUpdatedAt = new Date(updated_at).toDateString();
    
      // Use formattedUpdatedAt wherever you need it in your application
    
      return {
        title,
        price,
        sku,
        inventory_quantity,
        option1,
        option2,
        option3,
        updated_at: formattedUpdatedAt, // Replace the original updated_at with the formatted version
      };
    });


  return inventoryItems;
};

