import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import R from 'ramda';
import './WelcomePage.styles.scss';
import { Outlet } from 'react-router';
import SideBar from '../../organisms/SideBar/SideBar';

export default function WelcomePage() {
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));

  useEffect(() => {
    if (!R.isEmpty(user)) {
      dispatch(push('/home'));
    } else {
      dispatch(push('/login'));
    }
  }, [dispatch, user]);

  return <div className="root">Hamada</div>;
}
