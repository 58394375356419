import "./SmartTags.style.scss";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Image from 'react-bulma-companion/lib/Image';
import { push } from "redux-first-history";
import R from "ramda";
import QR from '../../../assets/images/scan-qrcode.svg';
export default function SmartTags() {
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(["user"]));

  
  const [loading, setLoading] = useState(true);
  
  const isAdmin = user.id == "64ea1aca6810343d7be47b42";

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  
  

  const [code, setCode] = useState(searchParams.get("code"));

  const [order, setOrder] = useState({});
  const [potentialOrder, setPotentialOrder] = useState();
  const [postData, setPostData] = useState({});

 
  const getTag = async (code) => {

    try {
      const response = await fetch(`/api/orders/scan?code=${code}`, {
        method: 'GET',
      });
      
      // Handle response
      const data = await response.json();
      setOrder(data.order);

    } catch (error) {
      console.error('Error:', error);
    }



  };

  
  const inquireOrder = async (order_number) => {

    console.log(order_number);
    if(order_number.length >= 4) {
      try {
        const response = await fetch(`/api/orders/inquire?order_number=${order_number}`, {
          method: 'GET',
        });        
        // Handle response
        const data = await response.json();
        setPotentialOrder(data.order);

      } catch (error) {
        console.error('Error:', error);
      }

    }
  };

  const getData = async () => {
    try {
      setLoading(true); // Set loading to true while fetching data
      
      await getTag(code);
      


    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (R.isEmpty(user)) {
      dispatch(push("/login"));
    } else {

      setPostData({code: code});

      getData();


    }
  }, []);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true); // Set loading state to true before making the request

    setCode(searchParams.get("code"));

    console.log(postData);
    try {
      const response = await fetch(`/api/orders/scan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      });
      // Handle response
      const data = await response.json();

    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); 
      setPotentialOrder();  
      setOrder();    
      getData();

    }
  };


  const handleUnlink = async () => {

    console.log(`unlink`);

    
    debugger;

    setLoading(true); // Set loading state to true before making the request

    debugger;
    try {
      const response = await fetch(`/api/orders/scan`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
      });
      // Handle response
      const data = await response.json();
      
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false); // Set loading state back to false after the request completes
      setPotentialOrder();  
      setOrder();    
      getData();

    }
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    setPostData(prevData => ({
      ...prevData,
      [name]: value
    }));

    if(name == 'order_number' && value.length > 3) inquireOrder(value);
  };



  return (
    <div  className="SmartTagContainer">
      

        {loading && 
        <div>Loading...</div>}

        {!loading && order && 
        <div className="popup">
          <div className="popup-inner">
          <img className="qr-img" src={QR} />
          <br/>
          <h2> Smart Code linked with Order</h2> 
            <label>
              Order Number: {order.name} Brand: {order.vendor}
            </label>
            <button type="button" onClick={handleUnlink}>Unlink</button>
        </div>
      </div>}

        {!loading && !order && 
        <div className="popup">
          <div className="popup-inner">
          <img className="qr-img" src={QR} />
          <br/>
          <h2> Smart Code not Connected to an order</h2>
          <br/>
          <form onSubmit={handleSubmit}>
            <label>
              {potentialOrder && <div>Order matched: {potentialOrder.name} Brand: {potentialOrder.vendor}</div>}
              <input type="text" name="order_number" onChange={handleChange} />
              <input type="hidden" name="code" onChange={handleChange} value={code}/>
            </label>
            {potentialOrder && <button type="submit">Link Code</button>}
          </form>
        </div>
      </div>}
    </div>
  );
}
