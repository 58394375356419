import "./Orders.style.scss";
import React, { useEffect, useState } from "react";
import { Tabs, Table, Tag, Input, Space, Button } from "antd";
import { QuestionCircleFilled } from "@ant-design/icons";
import { SearchOutlined } from "@ant-design/icons";
import { getOrders } from "../../../api/orders";
import { attemptGetOrders } from "../../../store/thunks/orders";
import { useDispatch, useSelector } from "react-redux";
import { push } from "redux-first-history";
import R from "ramda";
import { stringify } from "rc-field-form/es/useWatch";
export default function Orders() {
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(["user"]));

  const isAdmin = user.id == "64ea1aca6810343d7be47b42";

  const [totalSales, setTotalSales] = useState();
  const [totalCommissioned, SetTotalCommissioned] = useState();

  const [orders, setOrders] = useState();
  const [loading, setLoading] = useState(true);
  const [test, setTest] = useState();
  var rowData = {};
  const pageSize = 10; // Set the maximum number of rows per page
  let selectedRows = "";

  const pagination = {
    pageSize,
  };
  const columns = [
    {
      title: "Order",
      dataIndex: "name",
      align: "center",
      className: "TableCells",
    },
    {
      title: "Date",
      dataIndex: "formattedDate",
      align: "center",
      className: "TableCells",
      sorter: (a, b) => new Date(a.formattedDate) - new Date(b.formattedDate),
      defaultSortOrder: 'descend',
    },
    {
      title: "Total Sales",
      dataIndex: "priceEGP",
      align: "center",
      className: "TableCells",
    },
    {
      title: "Commissioned",
      dataIndex: "payableEGP",
      align: "center",
      className: "TableCells",
    },
    {
      title: "VISA Fees",
      dataIndex: "additional",
      align: "center",
      className: "TableCells",
    },
    {
      title: "Net Payable",
      dataIndex: "net",
      align: "center",
      className: "TableCells",
    },
    {
      title: "Items",
      dataIndex: "lineItemsCount",
      align: "center",
      className: "TableCells",
      width: 300,
    },
  ];

  if (isAdmin) {
    columns.push({
      title: "Vendor",
      dataIndex: "vendor_name",
      align: "center",
      className: "TableCells",
      width: 300,
      sorter: (a, b) => a.vendor_name.localeCompare(b.vendor_name),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Vendors"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={confirm}
            style={{ width: 300, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => {
                filterVendors(selectedKeys, orders);
                confirm();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                // filterVendors("", orders)
                clearFilters();
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
      onFilter: (value, record) => {
        const vendor_name = record.vendor_name.toString(); // Convert to string for case-insensitive comparison
        selectedRows = value.toLowerCase();
        // filterVendors(value.toLowerCase(), orders);
        console.log(value.length);
        if (value.length == 0) {
          return;
        } else {
          console.log("a");
          // filterVendors(value.toLowerCase(), orders);
          console.log("c");
          return vendor_name.toLowerCase().includes(value.toLowerCase());
        }
      },
      filterIcon: (filtered) => (
        <SearchOutlined style={{ fontSize: "24px", color: "white" }} />
      ),
    });
  }

  const getData = async () => {
    try {
      setLoading(true); // Set loading to true while fetching data
      const orders = await getOrders();
      console.log("this is list of data we supposedly fetched", orders);
      setOrders(orders);
      const total = calculateSales(orders);
      setTotalSales(total);
      setTest("1");
      console.log("e");
      const payable = calculateCommissioned(orders);
      SetTotalCommissioned(payable);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Set loading to false after fetching data (success or error)
    }
  };

  useEffect(() => {
    if (R.isEmpty(user)) {
      dispatch(push("/login"));
    } else {
      getData();
      console.log("UseEffect Rendered");
    }
  }, []);

  function calculateSales(data) {
    // console.log(data);
    return data.reduce((acc, item) => acc + parseFloat(item.price), 0);
  }
  function calculateCommissioned(data) {
    console.log("cc");
    return data.reduce((acc, item) => acc + parseFloat(item.payable), 0);
  }

  const filterorders = ({ orders, filter }) => {
    if (filter === "Unfulfilled") {
      return orders.filter(
        (orders) => orders.fulfillment_status == "Unfulfilled"
      );
    }

    if (filter === "Unpaid") {
      return orders.filter((orders) => orders.fulfillment_status == "Unpaid");
    }
    if (filter === "Open") {
      return orders.filter((orders) => orders.fulfillment_status == "Open");
    }
    if (filter === "Closed") {
      return orders.filter((orders) => orders.fulfillment_status == "Closed");
    }
    return orders;
  };

  function filterVendors(selectedRows, orders) {
    // console.log("/////////////////////////////////");
    console.log(selectedRows);
    console.log("b");
    rowData = orders;
    // console.log(rowData[0].vendor_name);
    // console.log(rowData[0].vendor_name.toLowerCase().includes(selectedRows));
    var updatedSales = 0;
    var updatedCommissions = 0;
    if (selectedRows.length > 0) {
      for (var i = 0; i < rowData.length; i++) {
        if (rowData[i].vendor_name.toLowerCase().includes(selectedRows)) {
          updatedSales = parseInt(updatedSales) + parseInt(rowData[i].price);
          updatedCommissions =
            parseInt(updatedCommissions) + parseInt(rowData[i].payable);
        }
      }
      // console.log(updatedSales);
      // console.log(updatedCommissions);
      setTotalSales(updatedSales);
      SetTotalCommissioned(updatedCommissions);
      console.log("c");
    } else {
      getData();
    }
  }
  function Children({ orders, filter }) {
    return (
      <div>
        <Table
          columns={columns}
          dataSource={filterorders({ orders, filter })}
          scroll={{
            // x: "max-content",
            y: 400,
          }}
          loading={loading}
          size="small"
          tableLayout="auto"
          expandable={true}
          pagination={pagination}
        />
        <div
          style={{
            fontWeight: "bold",
            textAlign: "right",
            paddingRight: "40px",
          }}
        >
          Total Sales: {totalSales}EGP
        </div>
      </div>
    );
  }
  const items = [
    {
      key: "1",
      label: "All",
      children: <Children orders={orders} filter="Default" />,
    }
  ];
  return (
    <div className="OrdersContainer">
      <Tabs
        className="OrdersTab"
        defaultActiveKey="1"
        items={items}
        tabBarStyle={{ marginBottom: 0, marginLeft: "1em" }}
        size="large"
      />
    </div>
  );
}
