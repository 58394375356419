export const SET_ORDERS = 'SET_ORDERS';
export const ADD_ORDER = 'ADD_ORDER';
export const TOGGLE_COMPLETE_ORDER = 'TOGGLE_COMPLETE_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const REMOVE_ORDER = 'REMOVE_ORDER';
export const INCREMENT_ORDER_ID = 'INCREMENT_ORDER_ID';

export const setOrders = orders => ({
  type: SET_ORDERS,
  orders,
});

export const addOrder = ({ id, text, createdAt }) => ({
  type: ADD_ORDER,
  createdAt,
  id,
  text,
});

export const toggleCompleteOrder = id => ({
  type: TOGGLE_COMPLETE_ORDER,
  id,
});

export const updateOrder = ({ id, text, updatedAt }) => ({
  type: UPDATE_ORDER,
  updatedAt,
  id,
  text,
});

export const removeOrder = id => ({
  type: REMOVE_ORDER,
  id,
});
