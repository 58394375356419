import React, { useEffect, useState } from 'react';
import './Products.style.scss';
import { Tabs, Button } from 'antd';
import { attemptGetProducts } from '_store/thunks/products';
import AOT from '../../../assets/images/AOT.jpg';
import ProductsTable from '../../molecules/Table/ProductsTable';

export default function Products() {
  const data = [
    {
      key: '1',
      logo: <img src={AOT} style={{ width: '50px', height: '50px' }} />,
      product: 'Black Gloves',
      status: 'Active',
      inventory: '150 in stock for 3 variants',
      type: 'Shirts and Tops',
      vendor: 'Kareem and Marc',
    },
    {
      key: '2',
      logo: <img src={AOT} style={{ width: '50px', height: '50px' }} />,
      product: 'American Eagle',
      status: 'Draft',
      inventory: '150 in stock for 3 variants',
      type: 'Shirts and Tops',
      vendor: 'Kareem and Marc',
    },
    {
      key: '3',
      logo: <img src={AOT} style={{ width: '50px', height: '50px' }} />,
      product: 'Black Love Hoodie',
      status: 'Active',
      inventory: '150 in stock for 3 variants',
      type: 'Shirts and Tops',
      vendor: 'Kareem and Marc',
    },
    {
      key: '4',
      logo: <img src={AOT} style={{ width: '50px', height: '50px' }} />,
      product: 'White AE Shirt',
      status: 'Draft',
      inventory: '150 in stock for 3 variants',
      type: 'Shirts and Tops',
      vendor: 'Kareem and Marc',
    },
  ];
  const [products, setProducts] = useState(data);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const items = [
    {
      key: '1',
      label: 'All',
      children: (
        <ProductsTable
          setProducts={(value) => setProducts(value)}
          filter="Default"
          products={products}
          isModalOpen={isModalOpen}
          setIsModalOpen={(value) => setIsModalOpen(value)}
        />
      ),
    },
    {
      key: '2',
      label: 'Active',
      children: (
        <ProductsTable
          setProducts={(value) => setProducts(value)}
          filter="Active"
          products={products}
          isModalOpen={isModalOpen}
          setIsModalOpen={(value) => setIsModalOpen(value)}
        />
      ),
    },
    {
      key: '3',
      label: 'Draft',
      children: (
        <ProductsTable
          setProducts={(value) => setProducts(value)}
          filter="Draft"
          products={products}
          isModalOpen={isModalOpen}
          setIsModalOpen={(value) => setIsModalOpen(value)}
        />
      ),
    },
  ];

  return (
    <div className="ProductsContainer">
      <Tabs
        className="ProductsTab"
        defaultActiveKey="1"
        items={items}
        tabBarExtraContent={(
          <Button
            style={{ marginRight: '1em' }}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            Add Product
          </Button>
        )}
        tabBarStyle={{ marginBottom: 0, marginLeft: '1em' }}
        size="large"
      />
    </div>
  );
}
