import './Inventory.style.scss';
import React, { useEffect, useState } from 'react';
import { Tabs, Table, Tag } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { getOrders } from '../../../api/inventory';
import { attemptGetOrders } from '../../../store/thunks/orders';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import R from 'ramda';
export default function Orders() {
  
  const dispatch = useDispatch();
  const { user } = useSelector(R.pick(['user']));
  

  
  const [orders, setOrders] = useState();
  const [loading, setLoading] = useState(true);

  // title, price, sku, inventory_quantity, option1, option2, option3 , updated_at 

  const pageSize = 10;  // Set the maximum number of rows per page

  const pagination = {
    pageSize,
  };

  
  const columns = [
    {
      title: 'Name',
      dataIndex: 'title',
      align: 'center',
      className: 'TableCells',
    },
    {
      title: 'Price',
      dataIndex: 'price',
      align: 'center',
      className: 'TableCells',
    },
    {
      title: 'SKU',
      dataIndex: 'sku',
      align: 'center',
      className: 'TableCells',
    },
    {
      title: 'Quantity',
      dataIndex: 'inventory_quantity',
      align: 'center',
      className: 'TableCells',
      width: 150,
    },
    {
      title: 'Option 1',
      dataIndex: 'option1',
      align: 'center',
      className: 'TableCells',
    },
    {
      title: 'Option 2',
      dataIndex: 'option2',
      align: 'center',
      className: 'TableCells',
    },
    {
      title: 'Option 3',
      dataIndex: 'option3',
      align: 'center',
      className: 'TableCells',
    },
    {
      title: 'Last Update',
      dataIndex: 'updated_at',
      align: 'center',
      className: 'TableCells',
    },
  ];

  const getData = async () => {
    try {
      setLoading(true); // Set loading to true while fetching data
      const orders = await getOrders();
      console.log('this is list of data we supposedly fetched', orders);
      setOrders(orders);

      

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false); // Set loading to false after fetching data (success or error)
    }
  };

  useEffect(() => {
      if (R.isEmpty(user)) {
      dispatch(push('/login'));
      } else {
        getData();
        console.log('UseEffect Rendered');
      }
  }, []);




    return (
      <div className="OrdersContainer">
        <Table
          columns={columns}
          dataSource={orders}
          scroll={{
            // x: "max-content",
            y: 400,
          }}
          loading={loading}
          size="small"
          tableLayout="auto"
          expandable={true}
          className="OrdersTab"
        />
      </div>
    );
  
}