import React, { useEffect, useState } from 'react';
import './ProductsTable.style.scss';
import {
  Table,
  Modal,
  Button,
  Popconfirm,
  InputNumber,
  Form,
  Input,
} from 'antd';
import AddProduct from '_components/molecules/AddProduct';

const setProductsTable = ({
  products,
  setProducts,
  filter,
  isModalOpen,
  setIsModalOpen,
}) => {
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();
  const isEditing = (record) => record.key === editingKey;

  useEffect(() => {
    filterProducts(products);
  }, [products]);
  const columns = [
    {
      title: 'logo',
      dataIndex: 'logo',
      align: 'center',
      className: 'LogoColumn',
      // editable: true,
    },
    {
      title: 'Product',
      dataIndex: 'product',
      align: 'center',
      className: 'ProductColumn',
      sorter: (a, b) => a.product.length - b.product.length,
      editable: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      // editable: true,
      className: 'StatusColumn',
      render: (text, record) => {
        const { status } = record;
        return (
          <div className={status == 'Active' ? 'ActiveStatus' : 'DraftStatus'}>
            {text}
          </div>
        );
      },
    },
    {
      title: 'Inventory',
      dataIndex: 'inventory',
      align: 'center',
      className: 'InventoryColumn',
      editable: true,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      align: 'center',
      className: 'TypeColumn',
      editable: true,
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      align: 'center',
      className: 'VendorColumn',
      editable: true,
    },

    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      className: 'ActionColumn',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button type="link" onClick={() => save(record.key)}>
              Save
            </Button>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <React.Fragment>
            <Button
              type="link"
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            >
              Edit
            </Button>
            <Button
              type="link"
              onClick={() => {
                deleteproduct(record);
              }}
            >
              Delete
            </Button>
          </React.Fragment>
        );
      },
    },
  ];

  function EditableCell({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }

  const edit = (record) => {
    form.setFieldsValue({
      product: record.product,
      inventory: record.inventory,
      type: record.type,
      vendor: record.vendor,
      ...record,
    });
    setEditingKey(record.key);
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...products];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setProducts(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setProducts(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const cancel = () => {
    setEditingKey('');
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const filterProducts = ({ products, filter }) => {
    if (filter === 'Active') {
      return products.filter((product) => product.status == 'Active');
    }

    if (filter === 'Draft') {
      return products.filter((product) => product.status == 'Draft');
    }
    return products;
  };

  const deleteproduct = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this record?',
      okText: 'Yes',
      onOk: () => {
        setProducts((pre) => pre.filter((product) => product.key !== record.key));
      },
    });
  };

  return (
    <React.Fragment>
      <Form form={form} component={false}>
        <div>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            tableLayout="auto"
            columns={mergedColumns}
            dataSource={filterProducts({ products, filter })}
            scroll={{
              y: 300,
            }}
            pagination={{
              onChange: cancel,
            }}
            expandable={true}
          />
        </div>
      </Form>

      {isModalOpen && (
        <AddProduct
          isModalOpen={isModalOpen}
          isModalOpenFun={(value) => setIsModalOpen(value)}
          products={(value) => setProducts(value)}
        />
      )}
    </React.Fragment>
  );
};

export default setProductsTable;
