import request from "superagent";
import { handleSuccess, handleError } from "_utils/api";

export const postOrder = (info) =>
  request.post("/api/orders").send(info).then(handleSuccess).catch(handleError);

export const getOrders = async () => {
  const data = await request
    .get("/api/orders")
    .then(handleSuccess)
    .catch(handleError);

  const filteredOrders = data.map((order) => {
    const {
      created_at,
      name,
      total_price,
      total_payable,
      additional_fees,
      net_pay,
      line_items,
      vendor,
    } = order;

    const lineItemsCount = line_items.map((item) => `${item.quantity}x ${item.name}`).join(", ");


    const fulfillment_status = line_items.fulfillment_status
      ? line_items.fulfillment_status
      : "Unfulfilled";
    const price = total_price;
    const priceEGP = `${total_price}EGP`;
    const payable = total_payable;
    const payableEGP = `${total_payable}EGP`;
    const additional = `${additional_fees ? additional_fees : 0} EGP`;
    const net = `${net_pay ? net_pay : payable} EGP`;
    const date = new Date(created_at);
    const formattedDate = date.toDateString();
    const vendor_name = vendor;
    return {
      formattedDate,
      name,
      price,
      priceEGP,
      payable,
      payableEGP,
      fulfillment_status,
      lineItemsCount,
      additional,
      net,
      vendor_name,
    };
  });

  return filteredOrders;
};
export const putToggleCompleteOrder = (info) =>
  request
    .put("/api/orders/complete")
    .send(info)
    .then(handleSuccess)
    .catch(handleError);

export const putOrder = (info) =>
  request.put("/api/orders").send(info).then(handleSuccess).catch(handleError);

export const deleteOrder = (info) =>
  request
    .delete("/api/orders")
    .send(info)
    .then(handleSuccess)
    .catch(handleError);
