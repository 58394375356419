import { snakeToCamelCase } from 'json-style-converter/es5';
import R from 'ramda';

import { getProducts, postProduct, putToggleCompleteProduct, putProduct, deleteProduct } from '_api/products';
import { setProducts, addProduct, toggleCompleteProduct, updateProduct, removeProduct } from '_store/actions/products';

import { dispatchError } from '_utils/api';

export const attemptGetProducts = () => dispatch =>
  getProducts()
    .then(data => {
      const products = R.map(product =>
        R.omit(['Id'], R.assoc('id', product._id, snakeToCamelCase(product))), data.products);

      dispatch(setProducts(products));
      return data.products;
    })
    .catch(dispatchError(dispatch));

export const attemptAddProduct = product => dispatch =>
  postProduct({ product })
    .then(data => {
      const product = R.omit(['Id'], R.assoc('id', data.product._id, snakeToCamelCase(data.product)));

      dispatch(addProduct(product));
      return data.user;
    })
    .catch(dispatchError(dispatch));

export const attemptToggleCompleteProduct = id => dispatch =>
  putToggleCompleteProduct({ id })
    .then(data => {
      dispatch(toggleCompleteProduct(id));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptUpdateProduct = (id, text) => dispatch =>
  putProduct({ id, text })
    .then(data => {
      dispatch(updateProduct({ id, text, updatedAt: data.product.updated_at }));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptDeleteProduct = id => dispatch =>
  deleteProduct({ id })
    .then(data => {
      dispatch(removeProduct(id));
      return data;
    })
    .catch(dispatchError(dispatch));
