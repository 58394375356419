export const SET_PRODUCTS = 'SET_PRODUCTS';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const TOGGLE_COMPLETE_PRODUCT = 'TOGGLE_COMPLETE_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const INCREMENT_PRODUCT_ID = 'INCREMENT_PRODUCT_ID';

export const setProducts = products => ({
  type: SET_PRODUCTS,
  products,
});

export const addProduct = ({ id,
  title,
  bodyHtml,
  productType,
  createdAt,
  handle,
  updatedAt,
  publishedAt,
  templateSuffix,
  tags }) => ({
  type: ADD_PRODUCT,
  title,
  bodyHtml,
  productType,
  createdAt,
  handle,
  updatedAt,
  publishedAt,
  templateSuffix,
  tags,
  id,
});

export const toggleCompleteProduct = id => ({
  type: TOGGLE_COMPLETE_PRODUCT,
  id,
});

export const updateProduct = ({ id, text, updatedAt }) => ({
  type: UPDATE_PRODUCT,
  updatedAt,
  id,
  text,
});

export const removeProduct = id => ({
  type: REMOVE_PRODUCT,
  id,
});
