import { snakeToCamelCase } from 'json-style-converter/es5';
import R from 'ramda';

import { getOrders, postOrder, putToggleCompleteOrder, putOrder, deleteOrder } from '_api/orders';
import { setOrders, addOrder, toggleCompleteOrder, updateOrder, removeOrder } from '_store/actions/orders';

import { dispatchError } from '_utils/api';

export const attemptGetOrders = () => dispatch =>
  getOrders()
    .then(data => {
      const orders = R.map(order =>
        R.omit(['Id'], R.assoc('id', order._id, snakeToCamelCase(order))), data.orders);

      dispatch(setOrders(orders));
      return data.orders;
    })
    .catch(dispatchError(dispatch));

export const attemptAddOrder = text => dispatch =>
  postOrder({ text })
    .then(data => {
      const order = R.omit(['Id'], R.assoc('id', data.order._id, snakeToCamelCase(data.order)));

      dispatch(addOrder(order));
      return data.user;
    })
    .catch(dispatchError(dispatch));

export const attemptToggleCompleteOrder = id => dispatch =>
  putToggleCompleteOrder({ id })
    .then(data => {
      dispatch(toggleCompleteOrder(id));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptUpdateOrder = (id, text) => dispatch =>
  putOrder({ id, text })
    .then(data => {
      dispatch(updateOrder({ id, text, updatedAt: data.order.updated_at }));
      return data;
    })
    .catch(dispatchError(dispatch));

export const attemptDeleteOrder = id => dispatch =>
  deleteOrder({ id })
    .then(data => {
      dispatch(removeOrder(id));
      return data;
    })
    .catch(dispatchError(dispatch));
